export const isauthenticated = () => {
  if (localStorage.getItem("auth")) {
    return true;
  }
  return false;
};

export const getUserDetails = () => {
  if (isauthenticated()) {
    const data = JSON.parse(localStorage.getItem("auth") || "");
    return data;
  }
  return null;
};

export const truncateData = (data, wordLimit) => {
  const words = data.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "...";
  }
  return data;
};
