import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import ScrollToTop from "../src/common/ScroolToTop.jsx";
import LoadingSpinner from "../src/common/LoadingSpinner.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isauthenticated } from "./utlis/common.js";

const Homepage = lazy(() => import("../src/Pages/layout/Homepage"));
const AboutUs = lazy(() => import("../src/Pages/layout/AboutUs"));
const UserDetailsForm = lazy(() => import("./common/UserDetailsForm"));
const ProductListingPage = lazy(() =>
  import("../src/Pages/layout/ProductListingPage")
);
const ThankYouPage = lazy(() => import("./common/ThankYou"));
const ProductDetail = lazy(() => import("./Pages/layout/ProductDetail"));

const AuthWrapper = ({ children }) => {
  const location = useLocation();
  const isAuthenticated = isauthenticated();

  React.useEffect(() => {
    if (!isAuthenticated) {
      localStorage.setItem("path", location.pathname);
    }
  }, [location, isAuthenticated]);

  return children;
};

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = isauthenticated();

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const PublicRoute = ({ children }) => {
  const isAuthenticated = isauthenticated();
  const path = localStorage.getItem("path");

  if (isAuthenticated && path) {
    localStorage.removeItem("path");
    return <Navigate to={path} replace />;
  }

  return children;
};

const AppRoutes = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route
        path="/"
        element={
          <PublicRoute>
            <Homepage />
          </PublicRoute>
        }
      />
      <Route
        path="/about"
        element={
          <PublicRoute>
            <AboutUs />
          </PublicRoute>
        }
      />
      <Route
        path="/:products/:id"
        element={
          <PublicRoute>
            <ProductListingPage />
          </PublicRoute>
        }
      />
      <Route
        path="/product/:product/:id"
        element={
          <PublicRoute>
            <ProductDetail />
          </PublicRoute>
        }
      />
      <Route
        path="/premium/:product/:id"
        element={
          <PublicRoute>
            <ProductDetail />
          </PublicRoute>
        }
      />

      {/* Protected Routes */}
      <Route
        path="/user-details"
        element={
          <ProtectedRoute>
            <UserDetailsForm />
          </ProtectedRoute>
        }
      />
      <Route
        path="/ThankYouPage"
        element={
          <ProtectedRoute>
            <ThankYouPage />
          </ProtectedRoute>
        }
      />

      {/* Catch all route */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

function App() {
  return (
    <Router>
      <AuthWrapper>
        <ToastContainer />
        <ScrollToTop />
        <Suspense fallback={<LoadingSpinner />}>
          <AppRoutes />
        </Suspense>
      </AuthWrapper>
    </Router>
  );
}

export default App;
