import React from "react";
import { Loader } from "lucide-react";

const LoadingSpinner = ({ screen }) => {
  return (
    <div className={`flex items-center justify-center ${"h-screen" || screen}`}>
      <Loader className="animate-spin text-steel-primary" size={48} />
    </div>
  );
};

export default LoadingSpinner;
